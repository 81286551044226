interface NeumaticoSchema {
  '@context': string
  '@type': string
  itemCondition: string
  name: string
  description: string
  image: string
  brand: string
  offers: {
    '@type': 'Offer'
    priceCurrency: 'EUR'
    price: string
  }
}

interface NeumaticoData {
  name: string
  description: string
  image: string
  brand: string
  price: string
}
export function makeNeumaticosSchema(data: NeumaticoData): NeumaticoSchema {
  return {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    itemCondition: 'NewCondition',
    name: data.name,
    description: data.description,
    image: data.image,
    brand: data.brand,
    offers: {
      '@type': 'Offer',
      priceCurrency: 'EUR',
      price: `${data.price}`,
    },
  }
}

interface Address {
  '@type': string
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  telephone: string
  addressCountry: string
}

interface Rating {
  ratingValue: string | number
  ratingCount: number
  bestRating: number
}
interface TallerSchema {
  '@context': string
  '@type': string
  name: string
  url: string
  image: string
  address: Address
  aggregateRating: Rating
}

interface TallerData {
  name: string
  url: string
  image: string
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  telephone: string
  addressCountry: string
  ratingValue: string | number
  ratingCount: number
  bestRating: number
}

export function makeTallerSchema(data: TallerData): TallerSchema {
  return {
    '@context': 'http://schema.org/',
    '@type': 'AutoRepair',
    name: data.name,
    url: data.url,
    image: data.image,
    address: {
      '@type': 'PostalAddress',
      streetAddress: data.streetAddress,
      addressLocality: data.addressLocality,
      addressRegion: data.addressRegion,
      postalCode: data.postalCode,
      telephone: data.telephone,
      addressCountry: data.addressCountry,
    },
    aggregateRating: {
      ratingValue: data.ratingValue,
      ratingCount: data.ratingCount,
      bestRating: data.bestRating,
    },
  }
}
