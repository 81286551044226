export const getDefaultNeumaticoUrl = (
  imageType: 'recortado' | 'ficha' = 'recortado'
): string => {
  switch (process.env.GATSBY_WEB) {
    case 'elpaso':
      return `https://media.elpaso2000.com/marca/default/neumaticos/${imageType}/imagen-neumatico.png`
    case 'rodimotor':
      return 'https://cloud.rodimotor.pt/marca/pneus-medio.png'
    case 'rodi':
    default:
      return `https://media.rodi.es/marca/default/neumaticos/${imageType}/imagen-neumatico.png`
  }
}
