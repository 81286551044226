import React, { ReactNode } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import styles from './accordion.module.scss'
import { t } from '../../../i18n'
import { NeumaticoInfo, NeumaticoProducto } from '../types'

interface Props {
  grouped: Record<string, Array<NeumaticoInfo & NeumaticoProducto>>
  header: ReactNode
  contentRenderFnc: (neumatico) => ReactNode
  itemHeader: string
}
export default function Wrapper({
  grouped,
  header,
  contentRenderFnc,
  itemHeader,
}: Props) {
  return (
    <div className={styles.accordion__wrapper}>
      {header}
      {Object.entries(grouped).length ? (
        <Accordion
          className={styles.accordion_noborder}
          allowZeroExpanded={true}>
          {Object.entries(grouped).map(([field, neumaticos]) => (
            <AccordionItem key={field} uuid={field}>
              <AccordionItemState>
                {({ expanded }) => (
                  <React.Fragment>
                    <AccordionItemHeading>
                      <AccordionItemButton
                        className={
                          expanded
                            ? styles.accordion__item_header__button_expanded
                            : styles.accordion__item_header__button
                        }>
                        <span className={styles.accordion__item_header__title}>
                          {t(itemHeader)} {field}
                        </span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                      className={styles.accordion__item_panel}>
                      {neumaticos.map((neumatico) =>
                        contentRenderFnc(neumatico)
                      )}
                    </AccordionItemPanel>
                  </React.Fragment>
                )}
              </AccordionItemState>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <p>{t('landing_neumaticos.no_resultados_filtro')}</p>
      )}
    </div>
  )
}
