import React from 'react'
import styles from './characteristics.module.scss'
import { t } from '../../../i18n'
import { getNeumaticoEstacion, getNeumaticoSpecs } from '../utils'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
} from '../types'

interface Props {
  modelo: ModeloCocheInfo
  neumatico?: NeumaticoCocheInfo & NeumaticoCocheProducto
  estaciones?: string[]
}
export const CharacteristicsCocheTable: React.FC<Props> = ({
  modelo,
  neumatico,
  estaciones,
}) => (
  <dl className={styles.characteristics__table}>
    <dt>{t('landing_neumaticos.titulos_caracteristicas_tecnicas.marca')}</dt>
    <dd>{modelo.marca.nombre}</dd>

    <dt>{t('landing_neumaticos.titulos_caracteristicas_tecnicas.modelo')}</dt>
    <dd> {modelo.modelo}</dd>

    {estaciones ? (
      <React.Fragment>
        <dt>
          {t('landing_neumaticos.titulos_caracteristicas_tecnicas.estacion')}
        </dt>
        <dd>{estaciones.join(', ')}</dd>
      </React.Fragment>
    ) : null}
    {neumatico ? (
      <React.Fragment>
        <dt>
          {t('landing_neumaticos.titulos_caracteristicas_tecnicas.estacion')}
        </dt>
        <dd>{getNeumaticoEstacion(neumatico)}</dd>
        <dt>
          {t('landing_neumaticos.titulos_caracteristicas_tecnicas.medidas')}
        </dt>
        <dd>{getNeumaticoSpecs(neumatico)}</dd>
      </React.Fragment>
    ) : null}
  </dl>
)
