import React, { useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import DropDown from '../dropDown/DropDown'
import styles from './filters.module.scss'
import Neumatico from '../../assets/images/menu/icon_neumaticos.svg'

export default function Filters({ options, onChange, currentSelection }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded, setExpanded])

  if (!options) {
    return null
  }

  return (
    <div className={`${styles.container} ${expanded ? styles.expanded : ''}`}>
      <div className={styles.title} onClick={toggleExpanded}>
        <Neumatico className={styles.icon} />
        <span className={styles.title_text}>
          <FormattedMessage id="landing_neumaticos.neumatico_form.filtrar" />
        </span>
        <span className={styles.arrow}>&nbsp;</span>
      </div>
      <div className={styles.dropdowns}>
        <DropDown
          size="big"
          name="ancho"
          value={currentSelection.ancho}
          onChange={onChange}
          options={options.ancho}
          labelId="selector-desktop.ancho"
          labelAbove={true}
        />
        <DropDown
          size="big"
          name="serie"
          value={currentSelection.serie}
          onChange={onChange}
          options={options.serie}
          labelId="selector-desktop.serie"
          labelAbove={true}
        />
        <DropDown
          size="big"
          name="llanta"
          value={currentSelection.llanta}
          onChange={onChange}
          options={options.llanta}
          labelId="selector-desktop.llanta"
          labelAbove={true}
        />
        <DropDown
          size="big"
          name="ic"
          value={currentSelection.ic}
          onChange={onChange}
          options={options.ic}
          labelId="selector-desktop.indice-carga"
          labelAbove={true}
        />
        <DropDown
          size="big"
          name="cv"
          value={currentSelection.cv}
          onChange={onChange}
          options={options.cv}
          labelId="selector-desktop.indice-velocidad"
          labelAbove={true}
        />
      </div>
    </div>
  )
}
