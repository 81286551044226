import React from 'react'
import { t, textWithLocale } from '../../../i18n'
import Button from '../../buttons/Button'
import { RequestTyreData } from '../../../types/Neumatico'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
} from '../types'

interface Props {
  modelo: ModeloCocheInfo
  neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
  selectNeumatico: (selected: RequestTyreData) => void
  openModal: (open: boolean) => void
}
const RequestTyreCTA: React.FC<Props> = ({
  modelo,
  neumatico,
  selectNeumatico,
  openModal,
}) => {
  const requestedNeumatico: RequestTyreData = {
    marca: modelo.marca.nombre,
    modelo: modelo.modelo,
    id_site_modelo_neumatico: modelo.id_site_modelo_neumatico,
    id_site_producto: neumatico.id_site_productos_neumaticos,
    tyreSpecs: {
      ancho: neumatico.ancho,
      serie: neumatico.serie,
      llanta: neumatico.llanta,
      cv: neumatico.cv,
      ic: neumatico.ic,
    },
    descripcion: neumatico.producto[textWithLocale('descripcion')],
  }

  const onClickRequest = () => {
    selectNeumatico(requestedNeumatico)
    openModal(true)
  }
  return (
    <Button
      disclaimerMessage={t('landing_neumaticos.mensaje_no_disponible')}
      buttonClass={'rodi_button_no_color'}
      onClick={onClickRequest}>
      {t('landing_neumaticos.ver_disponibilidad')}
    </Button>
  )
}

export default RequestTyreCTA
