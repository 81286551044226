import React from 'react'
import styles from './accordion.module.scss'
import { t } from '../../../i18n'

export interface HeaderItem {
  title: string
  className: string
}
interface Props {
  items: HeaderItem[]
}
const AccordionHeader: React.FC<Props> = ({ items }) => (
  <div className={styles.accordion__header}>
    <ul className={styles.accordion__header__item}>
      {items.map((headerItem) => (
        <li className={headerItem.className} key={headerItem.title}>
          {t(headerItem.title)}
        </li>
      ))}
    </ul>
  </div>
)
export default AccordionHeader
