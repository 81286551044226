import { useState, useEffect } from 'react'
import { PromoBannerRequestParams } from '../../types/Promociones'
import * as api from '../../api/neumaticos'
import { Promocion } from '../../components/promotionBanner/PromotionBanner'

function useUpdatePromocion(params: PromoBannerRequestParams) {
  const [updatedPromotion, setUpdatedPromotion] = useState<Promocion>({
    texto_promociones: null,
    color_background: null,
    color_texto: null,
    visible_cesta: true,
  })
  const updateTextoPromociones = async (params: PromoBannerRequestParams) => {
    const { texto_promociones, color_background, color_texto } =
      await api.fetchInfoPromocion(params)

    return { texto_promociones, color_background, color_texto }
  }

  useEffect(() => {
    updateTextoPromociones(params).then(
      ({ color_background, color_texto, texto_promociones }) => {
        setUpdatedPromotion((prevState) => ({
          ...prevState,
          color_background,
          color_texto,
          texto_promociones,
        }))
      }
    )
  }, [])

  return updatedPromotion
}

export default useUpdatePromocion
