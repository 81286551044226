import React from 'react'
import { IAppState } from '../../../context/context'
import { t } from '../../../i18n'
import * as CestaActions from '../../../context/actions/cestaActions'
import { ProductInfo } from '../../../context/actions/cestaActions'
import { DataSteps, Steps } from '../../cesta/Steps'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { selectPostCode } from '../../../context/selectors/userSelectors'
import Button from '../../buttons/Button'
import { CategoriaNeumatico } from '../../../types/Neumatico'
import { NeumaticoInfo, NeumaticoMarca, NeumaticoProducto } from '../types'
import { neumaticoActivo } from '../utils'

interface Props {
  onSelectedNeumatico: (productInfo: ProductInfo) => void
  neumaticoInfo: NeumaticoInfo & NeumaticoProducto
  marcaInfo: NeumaticoMarca
  buttonClass?: string
}
const ComprarNeumaticoCTA: React.FC<Props> = ({
  onSelectedNeumatico,
  neumaticoInfo,
  marcaInfo,
  buttonClass = 'rodi_full_width',
}) => {
  const { producto } = neumaticoInfo
  const { nombre: marca, clasificacion } = marcaInfo
  const activo = neumaticoActivo(producto)
  const productInfo: ProductInfo = {
    id: producto.id_navision,
    categoria: clasificacion as CategoriaNeumatico,
    marca,
    type: 'neumatico',
  }

  const onClickComprar = () => {
    if (activo) {
      onSelectedNeumatico(productInfo)
    }
  }
  return (
    <Button
      buttonClass={buttonClass}
      onClick={onClickComprar}
      disabled={!activo}>
      {t('landing_neumaticos.comprar')}
    </Button>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSelectedNeumatico: (productInfo: ProductInfo) => {
      dispatch(CestaActions.addProduct(productInfo, 'database'))
      dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
      navigate(DataSteps[Steps.TU_SOLICITUD].route)
    },
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComprarNeumaticoCTA)
