import React, { RefObject } from 'react'
import { t } from '../../../i18n'
import Button from '../../buttons/Button'

interface Props {
  reference: RefObject<HTMLDivElement>
}
const SeleccionarMedidasCTA: React.FC<Props> = ({ reference }) => {
  const scrollToFiltro = () => {
    reference.current &&
      window.scrollTo({
        left: 0,
        top: reference.current.offsetTop,
        behavior: 'smooth',
      })
  }
  return (
    <Button buttonClass={'rodi_full_width'} onClick={scrollToFiltro}>
      {t('landing_neumaticos.info.button_seleccionar')}
    </Button>
  )
}

export default SeleccionarMedidasCTA
