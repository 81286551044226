import { graphql, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { t, textWithLocale } from '../../i18n'
import Layout from '../../components/Layout'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import styles from './fichas.module.scss'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticosEstaciones,
  selectLowestPrice,
} from '../../components/FichasNeumaticos/utils'
import NeumaticoCard from '../../components/FichasNeumaticos/Cards/NeumaticoCard'
import SeleccionarMedidasCTA from '../../components/FichasNeumaticos/Ctas/SeleccionarMedidasCTA'
import NeumaticosAccordion from '../../components/FichasNeumaticos/Accordion/NeumaticosAccordion'
import Filters from '../../components/landingNeumaticos/Filters'
import logic from '../../logic'

const deepIsEqual = (first: unknown, second: unknown) => {
  if (first === second) return true

  // Try a quick compare by seeing if the length of properties are the same
  const firstProps = Object.getOwnPropertyNames(first)
  const secondProps = Object.getOwnPropertyNames(second)

  if (firstProps.length !== secondProps.length) return false

  for (let i = 0; i < firstProps.length; i++) {
    const prop = firstProps[i]
    switch (typeof first[prop]) {
      // If it is an object, decend for deep compare
      case 'object':
        if (!deepIsEqual(first[prop], second[prop])) return false
        break
      case 'number':
        if (isNaN(first[prop]) && isNaN(second[prop])) break
        return false
      default:
        if (first[prop] !== second[prop]) return false
    }
  }
  return true
}
const filterData = (
  arr: Queries.FichaModelosPageQuery['neumaticosInfo']['nodes'],
  newFilters: Record<string, unknown>
) => {
  return arr.filter((item) => {
    const obj = {}
    Object.keys(item).forEach((k) => {
      if (Object.prototype.hasOwnProperty.call(newFilters, k)) {
        obj[k] = item[k]
      }
    })
    return deepIsEqual(obj, newFilters)
  })
}
const resetInitialOptions = () => ({
  ancho: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  serie: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  llanta: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  ic: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  cv: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
})
export default function FichaModelosPage({
  data: {
    modeloInfo,
    neumaticosInfo: { nodes: neumaticos },
    anchoOptions: { distinct: anchoDropDownOptions },
    serieOptions: { distinct: serieDropDownOptions },
    llantaOptions: { distinct: llantaDropDownOptions },
    cvOptions: { distinct: cvDropDownOptions },
    icOptions: { distinct: icDropDownOptions },
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_pt_desktop,
    banners_es_mobile,
    banners_ca_mobile,
    banners_pt_mobile,
  },
}: PageProps<Queries.FichaModelosPageQuery>) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
    pt_desktop: banners_pt_desktop.nodes,
    pt_mobile: banners_pt_mobile.nodes,
  }

  const { fichas } = getModeloImagenes(imagenes)

  const medidasRef = React.createRef<HTMLDivElement>()

  const [dropDownOptions, setDropDownOptions] = useState(null)
  const [dropDownSelection, setDropDownSelection] = useState({})
  const [filtrosSelected, setFiltrosSelected] = useState({})
  const [filteredNeumaticos, setFilteredNeumaticos] = useState(neumaticos)

  const getNewDropDownOptions = async (value, name) => {
    const newDropDownSelection = { ...dropDownSelection, [name]: value }
    let query
    if (Object.keys(newDropDownSelection).length === 5) {
      return
    }
    if (!Object.keys(newDropDownSelection).length) {
      query = `${name}=${value}`
    } else {
      query = Object.keys(newDropDownSelection)
        .filter((k) => newDropDownSelection[k] !== '')
        .map((item) => `${item}=${newDropDownSelection[item]}`)
        .join('&')
    }
    query = `${query}&idModelo=${modeloInfo.id_site_modelo_neumatico}`
    const calculatedOptions = await logic.retrieveAllDropDownOptions(query)
    Object.keys(calculatedOptions).forEach((k) =>
      calculatedOptions[k].unshift({
        label: t('landing_neumaticos.drop_down_all'),
        value: '',
      })
    )
    const newOptions = { ...dropDownOptions, ...calculatedOptions }
    setDropDownOptions(newOptions)
  }
  const handleFilterChange = (event) => {
    const { value, name } = event.target
    setDropDownSelection((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
    getNewDropDownOptions(value, name)
    const newFilters = { ...filtrosSelected, [name]: value }
    Object.keys(newFilters).forEach(
      (key) => newFilters[key] === '' && delete newFilters[key]
    )
    setFiltrosSelected(newFilters)
    const filteredData = filterData(neumaticos, newFilters)
    setFilteredNeumaticos(filteredData)
  }

  useEffect(() => {
    const initialOptions = resetInitialOptions()
    anchoDropDownOptions.forEach(
      (item) =>
        !initialOptions.ancho.some((i) => i.value === item) &&
        initialOptions.ancho.push({ label: item, value: item })
    )
    serieDropDownOptions.forEach(
      (item) =>
        !initialOptions.ancho.some((i) => i.value === item) &&
        initialOptions.serie.push({ label: item, value: item })
    )
    llantaDropDownOptions.forEach(
      (item) =>
        !initialOptions.ancho.some((i) => i.value === item) &&
        initialOptions.llanta.push({ label: item, value: item })
    )
    cvDropDownOptions.forEach(
      (item) =>
        !initialOptions.ancho.some((i) => i.value === item) &&
        initialOptions.cv.push({ label: item, value: item })
    )
    icDropDownOptions.forEach(
      (item) =>
        !initialOptions.ancho.some((i) => i.value === item) &&
        initialOptions.ic.push({ label: item, value: item })
    )
    setDropDownOptions(initialOptions)
  }, [])

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', { marca: marca.toLowerCase() }),
        },
        {
          display: modelo,
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: getModeloMarca(modeloInfo),
              image: fichas.length && fichas[0].url,
              description: modeloInfo[textWithLocale('recomendado_para')],
              brand: marca,
              price: selectLowestPrice(
                neumaticos.map((neum) => neum.producto)
              ).toString(),
            })
          ),
        }}
      />
      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <NeumaticoCard
            modelo={modeloInfo}
            banners={banners}
            cta={<SeleccionarMedidasCTA reference={medidasRef} />}
            estaciones={getNeumaticosEstaciones(neumaticos)}
          />
        </section>
        <section
          className={styles.ficha_neumatico__accordion_content}
          ref={medidasRef}>
          <h2 className={styles.total_count}>
            {neumaticos.length}{' '}
            {t('landing_neumaticos.info.medidas_encontradas')} {marca} {modelo}
          </h2>
          <Filters
            currentSelection={dropDownSelection}
            onChange={handleFilterChange}
            options={dropDownOptions}
          />
          <NeumaticosAccordion
            modelo={modeloInfo}
            neumaticos={filteredNeumaticos}
          />
        </section>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FichaModelosPage(
    $idModelo: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_desktop: String
    $banners_pt_mobile: String
  ) {
    modeloInfo: modelos(id_site_modelo_neumatico: { eq: $idModelo }) {
      ...modeloInfo
      ...modeloMarca
      ...modeloImagen
    }
    neumaticosInfo: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      nodes {
        ...neumaticoInfo
        ...productoInfo
      }
    }

    anchoOptions: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      distinct(field: ancho)
    }
    serieOptions: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      distinct(field: serie)
    }
    llantaOptions: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      distinct(field: llanta)
    }
    cvOptions: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      distinct(field: cv)
    }
    icOptions: allNeumaticos(
      filter: { id_site_modelo_neumatico: { eq: $idModelo } }
    ) {
      distinct(field: ic)
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
