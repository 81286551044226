/* eslint-disable @typescript-eslint/no-var-requires */
// This file cannot be typescript because it is used from
// gatsby-node.js

const rintl = require('react-intl')

const locale = process.env.GATSBY_LOCALE || 'es'
const flatten = require('flat').flatten
// const messages = flatten(require(`../spi18n/${locale}.json`))
const messages = flatten(require(`${__dirname}/../../specific/i18n/${locale}.json`));

const provider = new rintl.IntlProvider(
  { locale, messages },
  {}
).getChildContext()
const intl = provider.intl

module.exports = { locale, messages, intl }
