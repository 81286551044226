import { apiCall } from './api'
import {
  PromoBannerRequestParams,
  PromoForPromoBanner,
} from '../types/Promociones'

const baseUrl = `${process.env.GATSBY_API_URL}`

export function fetchInfoPromocion(
  params: PromoBannerRequestParams
): Promise<PromoForPromoBanner> {
  const url = new URL(`${baseUrl}/neumaticos/promocion`)
  Object.keys(params)
    .filter((key) => typeof params[key] !== 'undefined')
    .forEach((key) => url.searchParams.append(key, params[key]))
  return apiCall(url.href, {
    method: 'GET',
  })
}
