import React from 'react'
import EcoLabelBox from './EcoLabelBox'
import { ConsumoLevels, LLuviaLevels, RuidoLevels } from '../../types/Ecolabel'
import styles from './index.module.scss'
import { t } from '../../i18n'

type Props = {
  consumo?: ConsumoLevels
  lluvia?: LLuviaLevels
  ruido?: RuidoLevels
  decibelios?: number
  eprel?: string
  smallMobile?: boolean
}

const EcoLabels = ({
  consumo,
  lluvia,
  ruido,
  decibelios,
  eprel,
  smallMobile = false,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.ecolabels_wrapper} ${
          smallMobile ? styles.ecolabels_small : ''
        }`}>
        {!!consumo && (
          <EcoLabelBox icon="consumo" colour={`consumo_${consumo}`}>
            {consumo}
          </EcoLabelBox>
        )}
        {!!lluvia && (
          <EcoLabelBox icon="lluvia" colour={`lluvia_${lluvia}`}>
            {lluvia}
          </EcoLabelBox>
        )}
        {(!!ruido || (!!decibelios && decibelios > 0)) && (
          <EcoLabelBox icon="decibelios" colour={`decibelios`}>
            {ruido ? `${ruido} ` : ''}
            {decibelios > 0 ? `${decibelios}dB` : ''}
          </EcoLabelBox>
        )}
      </div>
      <div className={styles.eprel_wrapper}>
        <a
          href={`https://eprel.ec.europa.eu/screen/product/tyres/${
            eprel || ''
          }`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.eprel_link}>
          {t('eprel_text')}
        </a>
      </div>
    </div>
  )
}

export default EcoLabels
