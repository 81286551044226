import React from 'react'
import styles from './characteristics.module.scss'
import { t, textWithLocale } from '../../../i18n'
import { ModeloInfo } from '../types'

interface Props {
  modelo: ModeloInfo
  title?: string
  textKeys: string[]
}
export const CharacteristicsParagraphs: React.FC<Props> = ({
  modelo,
  title,
  textKeys,
}) => (
  <div className={styles.characteristics__paragraph_block}>
    {title && <h2>{t(title)}</h2>}
    {textKeys.map((key) =>
      modelo[textWithLocale(key)] ? (
        <p
          className={`${styles.characteristics__text} ${styles.characteristics__paragraph}`}
          key={`characteristics_p_${key}`}>
          {modelo[textWithLocale(key)]}
        </p>
      ) : null
    )}
  </div>
)
