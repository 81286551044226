/* eslint-disable @typescript-eslint/no-var-requires */
// This file cannot be typescript because it is used from
// gatsby-node.js

const i18n = require('../../common/i18n/server.js')

function _resolve(id, values) {
  const base = `route.${id}`

  if (i18n.messages[base]) {
    return i18n.intl.formatMessage({ id: base }, values)
  }

  const index = `${base}.index`
  if (i18n.messages[index]) {
    return i18n.intl.formatMessage({ id: index }, values)
  }

  return i18n.intl.formatMessage({ id: base }, values)
}

function route(id, values) {
  const path = _resolve(id, values)
  return path.endsWith('/') || path.endsWith('.html') ? path : `${path}/`
}

const routes = Object.keys(i18n.messages).filter(id => id.startsWith('route.'))

function listClientRoutes(id) {
  const base = `route.${id}`
  const list = routes.filter(id => id.startsWith(base))
  return list
}

module.exports = {
  route,
  listClientRoutes,
}
