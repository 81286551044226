import styles from './accordion.module.scss'
import React, { useMemo, useState } from 'react'
import { groupNeumaticos } from '../utils'
import AccordionHeader from './AccordionHeader'
import Accordion from './Accordion'
import { RequestTyreData } from '../../../types/Neumatico'
import ComprarNeumaticoCTA from '../Ctas/ComprarNeumaticoCTA'
import StandardModal from '../../modal/standardModal/StandardModal'
import { t } from '../../../i18n'
import RequestTyreCTA from '../Ctas/RequestTyreCTA'
import RequestTyre from '../../RequestTyre/RequestTyre'
import { AccordionContent } from './AccordionContent'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
  NeumaticosCoche,
} from '../types'

const headerItems = [
  {
    title: 'landing_neumaticos.list_header.item_one',
    className: styles.header_title,
  },
  {
    title: 'landing_neumaticos.list_header.item_two',
    className: styles.header_type,
  },
  {
    title: 'landing_neumaticos.list_header.item_three',
    className: styles.header_info,
  },
  {
    title: 'landing_neumaticos.list_header.item_four',
    className: styles.header_button,
  },
]

interface Props {
  modelo: ModeloCocheInfo
  neumaticos: NeumaticosCoche
}
export default function NeumaticosAccordion({ modelo, neumaticos }: Props) {
  const [showModal, setShowModal] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreData | null
  )
  const [successMessage, setSuccessMessage] = useState(false)

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const grouped = useMemo(
    () => groupNeumaticos(neumaticos, 'ancho'),
    [neumaticos]
  )

  const doContent = (
    neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
  ) => {
    return (
      <AccordionContent
        key={`accordion_${neumatico.producto.id_navision}`}
        neumatico={neumatico}
        modelo={modelo}
        comprarCta={
          <ComprarNeumaticoCTA
            neumaticoInfo={neumatico}
            marcaInfo={modelo.marca}
            buttonClass={'rodi_button'}
          />
        }
        requestTyreCta={
          <RequestTyreCTA
            neumatico={neumatico}
            modelo={modelo}
            openModal={setShowModal}
            selectNeumatico={setSelectedNeumatico}
          />
        }
      />
    )
  }
  return (
    <React.Fragment>
      <Accordion
        grouped={grouped}
        header={<AccordionHeader items={headerItems} />}
        contentRenderFnc={doContent}
        itemHeader={'landing_neumaticos.list_item_header.ancho'}
      />
      <StandardModal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
        />
      </StandardModal>
    </React.Fragment>
  )
}
