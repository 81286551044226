import React, { useState } from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import Done from '../Done/Done'
import { t } from '../../i18n'
import logic from '../../logic'
import route from '../../utils/route'
import { alertProp } from '../../utils/swal'
import Button from '../buttons/Button'
import CheckBoxYellowCesta from '../checkboxYellowCesta/CheckBoxYellowCesta'
import FormError from '../FormError/FormError'
import InputCesta from '../inputCesta/InputCesta'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'
import { RequestTyreData } from '../../types/Neumatico'
import { ConsumoLevels, LLuviaLevels, RuidoLevels } from '../../types/Ecolabel'
import Size from '../../utils/media-queries-setup'

interface EmailData {
  email: string
  checkboxPrivacy: boolean
}

const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .email(t('validations.register.valid_email'))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
})

interface InSiteMarcaNeumatico {
  clasificacion: 'BUDGET' | 'QUALITY' | 'PREMIUM'
  logo_marca: string
  marca: string
}

interface InSiteModeloNeumatico {
  id_site_modelo_neumatico: number
  modelo: string
  site_marca_neumatico: InSiteMarcaNeumatico
}

interface InSiteProducto {
  activo_ep: number
  activo_rodi: number
  disponibilidad: number
  id_navision: string
  id_site_producto: number
}

export interface InSelectedNeumatico {
  ancho: string
  consumo: ConsumoLevels | null
  cv: string
  decibelios: number
  ic: string
  id_site_productos_neumaticos: number
  llanta: string
  lluvia: LLuviaLevels | null
  marcaje: string | null
  nivel_ruido: RuidoLevels | null
  eprel: string | null
  serie: string
  site_modelo_neumatico: InSiteModeloNeumatico
  site_producto: InSiteProducto
}

interface Props {
  selectedNeumatico: RequestTyreData
  successMessage: boolean
  setSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
}
export default function RequestTyre({
  selectedNeumatico,
  successMessage,
  setSuccessMessage,
}: Props) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    triggerValidation,
  } = useForm<EmailData>({
    mode: 'onChange',
    validationSchema: EmailSchema,
  })
  const [loading, setLoading] = useState(false)
  const device = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'
  const onSubmit = (data) => {
    const { email } = data
    setLoading(true)
    return logic
      .requestNeumaticoAvailability(
        email,
        selectedNeumatico.marca,
        selectedNeumatico.modelo,
        selectedNeumatico.id_site_modelo_neumatico,
        selectedNeumatico.id_site_producto,
        selectedNeumatico.tyreSpecs,
        device
      )
      .then((res) => {
        if (res.errors) {
          res.errors.forEach((e) => {
            setError(e.field, e.type, t(e.message))
          })
          setLoading(false)
          return
        }
        setLoading(false)
        setSuccessMessage(true)
      })
      .catch((err) => {
        setLoading(false)
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: err.message,
          })
        )
      })
  }

  return (
    <section>
      {loading && <SpinnerWithText text={t('alta_vehiculo.cargando')} />}
      {!successMessage && (
        <div className={loading ? styles.hidden : styles.email_input_container}>
          {selectedNeumatico && (
            <div className={styles.request_email_message}>
              <p>
                {t('landing_neumaticos.neumatico_item.pedir_info_mensaje', {
                  marca: selectedNeumatico.marca,
                  modelo: selectedNeumatico.modelo,
                  specsText: selectedNeumatico.descripcion || '',
                })}
              </p>
              {t(
                'landing_neumaticos.neumatico_item.pedir_info_mensaje_contacto'
              )}
            </div>
          )}

          <form
            onChange={(e) => {
              const target = e.target as HTMLInputElement
              // Revalidar cualquier campo cuando cambia su valor
              clearError(target.name as keyof EmailData)
              triggerValidation({
                name: target.name as keyof EmailData,
              })
            }}
            className={styles.request_neumatico_form}
            onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.input_wrapper}>
              <InputCesta
                inputSize="big"
                label={
                  <div className={styles.label_email}>{t('login.email')}</div>
                }
                name="email"
                refe={register}
                errors={errors}
                type="text"
              />
            </div>
            <CheckBoxYellowCesta
              label={
                <div className={styles.label_privacy}>
                  <span>{t('registerForm.checkbox-privacy-read')}</span>
                  <a
                    className={styles.label_privacy_link}
                    href={route('politica-privacidad.index')}
                    target="_blank"
                    rel="noreferrer">
                    {t('registerForm.checkbox-privacy-policy')}
                  </a>
                </div>
              }
              name="checkboxPrivacy"
              refe={register}
            />
            <FormError errors={errors} field="checkboxPrivacy" />
            <Button buttonClass="rodi_button" type="submit">
              {t('registerForm.enviar')}
            </Button>
          </form>
        </div>
      )}
      {successMessage && (
        <div className={styles.send_email_success}>
          <Done />
          <div className={styles.email_success_message}>
            {t('landing_neumaticos.solicitar')}
          </div>
        </div>
      )}
    </section>
  )
}
