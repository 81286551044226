import React, { useState } from 'react'
import { getDefaultNeumaticoUrl } from '../../utils/getDefaultNeumaticoImageUrl'
import styles from './index.module.scss'

interface Props {
  fotos: { url: string }[]
  marca: string
  alt?: string
  tipo?: 'ficha' | 'recorte'
}
export default function ImageGallery({
  fotos,
  marca,
  alt,
  tipo = 'ficha',
}: Props) {
  const [clickedFoto, setClickedFoto] = useState(
    fotos.length ? fotos[0].url : getDefaultNeumaticoUrl()
  )
  const [selectedIndex, setSelectedIndex] = useState(null)
  const MarcaLogo = require(`../../assets/images/logo_marcas/${marca}.svg`)
  const onClick = (item, index) => {
    setClickedFoto(item)
    setSelectedIndex(index)
  }
  return (
    <div
      className={`${styles.image_gallery} ${
        tipo === 'recorte' ? styles.image_gallery_recorte : ''
      }`}>
      <div className={styles.image_modelo}>
        <MarcaLogo className={styles.marcaLogo} />
      </div>
      <div className={styles.image_container}>
        <img
          className={styles.image_gallery__displayed_image}
          src={clickedFoto}
          alt={alt || 'Modelo'}
        />
      </div>
      {fotos.length > 1 ? (
        <div className={styles.mini_fotos}>
          {fotos.map((item, index) => (
            <div
              key={index}
              onClick={() => onClick(item.url, index)}
              className={
                selectedIndex === index
                  ? styles.mini_foto_item_selected
                  : styles.mini_foto_item
              }>
              <img src={item.url} alt={marca} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
