import { getDefaultNeumaticoUrl } from '../../utils/getDefaultNeumaticoImageUrl'
import { t } from '../../i18n'

import {
  ModeloCocheInfo,
  ModeloImagenes,
  ModeloInfo,
  ModeloMotoInfo,
  NeumaticoCocheInfo,
  NeumaticoInfo,
  NeumaticoMotoInfo,
  NeumaticoProducto,
  Neumaticos,
  NeumaticosCoche,
  NeumaticosMoto,
} from './types'
import { route } from '../../utils/route-server'

export const generateFichaNeumaticoUrl = (
  neumatico: NeumaticoCocheInfo,
  modelo: ModeloCocheInfo
): string =>
  route('neumaticos.ficha', {
    marca: modelo.marca.nombre.toLowerCase(),
    modelo: modelo.slug.toLowerCase(),
    specs: `${neumatico.ancho}-${neumatico.serie}-r${neumatico.llanta}-${
      neumatico.ic
    }-${neumatico.cv.toLowerCase()}-${neumatico.id_site_producto}`,
  })

export const getModeloImagenes = (imagenes: ModeloImagenes) => {
  const fichas = imagenes
    .filter((item) => item.tipo.tipo_imagen === 'ficha')
    .map((item) => ({
      url: item.url,
    })) || [{ url: getDefaultNeumaticoUrl('ficha') }]

  const recortes = imagenes
    .filter((item) => item.tipo.tipo_imagen === 'recorte')
    .map((item) => ({
      url: item.url,
    })) || [{ url: getDefaultNeumaticoUrl('recortado') }]

  return { fichas, recortes }
}

export const neumaticoActivo = (producto: NeumaticoProducto['producto']) => {
  return !!(process.env.GATSBY_WEB === 'elpaso'
    ? producto.activo_ep
    : producto.activo_rodi)
}

export const productoActivo = (producto: NeumaticoProducto['producto']) => {
  return neumaticoActivo(producto)
}

export const priceWithTax = (
  producto: NeumaticoProducto['producto']
): number => {
  const impuesto =
    process.env.GATSBY_WEB === 'elpaso' ? producto.tipo_igic : producto.tipo_iva

  return (
    Math.round(Number(producto.precio) * (1 + Number(impuesto) / 100) * 100) /
    100
  )
}

export const generateFichaNeumaticoMotoUrl = (
  neumatico: NeumaticoMotoInfo,
  modelo: ModeloMotoInfo
): string => {
  const specs = [
    neumatico.ancho.replace(/[\s]/g, '').replace(/,/g, '-').replace(/\//g, '-'),
    neumatico.alto,
    `${neumatico.radial?.toLowerCase() || ''}${neumatico.llanta}`,
    neumatico.serie.toLowerCase(),
    neumatico.normativa.toLowerCase(),
    neumatico.id_site_producto,
  ]
    .filter(Boolean)
    .join('-')

  return route('neumaticos-moto.ficha', {
    marca: modelo.marca.nombre.toLowerCase(),
    modelo: modelo.slug.toLowerCase(),
    specs,
  })
}

export const getNeumaticoMotoSpecs = (neumatico: NeumaticoMotoInfo): string =>
  `${neumatico.ancho}${neumatico.alto ? `/${neumatico.alto}` : ''} ${
    neumatico.radial || ''
  } ${neumatico.llanta} ${neumatico.serie} ${neumatico.normativa}`

export const getNeumaticoSpecs = (neumatico: NeumaticoCocheInfo): string =>
  `${neumatico.ancho}/${neumatico.serie} R${neumatico.llanta} ${neumatico.ic} ${neumatico.cv}`

export const getModeloMarca = (modeloInfo: ModeloInfo): string => {
  const {
    modelo,
    marca: { nombre: marca },
  } = modeloInfo
  return `${marca} ${modelo}`
}

export const selectLowestPrice = (productos: NeumaticoProducto['producto'][]) =>
  Math.min(...productos.map((producto): number => priceWithTax(producto)))

export const groupNeumaticos = (neumaticos: Neumaticos, field: string) =>
  neumaticos.reduce(
    (
      acc: Record<string, Array<NeumaticoInfo & NeumaticoProducto>>,
      neumatico: NeumaticoInfo & NeumaticoProducto
    ) => {
      if (neumatico[field] in acc) {
        acc[neumatico[field]].push(neumatico)
        return acc
      }
      return {
        ...acc,
        [neumatico[field]]: [neumatico],
      }
    },
    {} as Record<string, Array<NeumaticoInfo & NeumaticoProducto>>
  )

export const getMotoLabels = (neumaticos: NeumaticosMoto) =>
  [
    ...neumaticos.reduce((acc, neumatico) => {
      acc.add(neumatico.gama)
      acc.add(neumatico.tipo)
      return acc
    }, new Set<string>()),
  ] as string[]

export const getNeumaticoEstacion = (neumatico: NeumaticoCocheInfo) => {
  return neumatico.allseason === 1
    ? t('ficha-marca.todo-el-año')
    : neumatico.verano === 1
    ? t('ficha-marca.verano')
    : neumatico.invierno === 1
    ? t('ficha-marca.invierno')
    : ''
}

export const getNeumaticosEstaciones = (neumaticos: NeumaticosCoche) =>
  [
    ...neumaticos.reduce(
      (acc, neumatico) => acc.add(getNeumaticoEstacion(neumatico)),
      new Set<string>()
    ),
  ] as string[]
