import React, { ReactNode } from 'react'
import styles from './index.module.scss'
import Consumo from '../../assets/ecolabels/consumo.svg'
import Lluvia from '../../assets/ecolabels/lluvia.svg'
import Ruido from '../../assets/ecolabels/ruido.svg'

type Props = {
  children: ReactNode
  colour?: string
  icon: 'consumo' | 'lluvia' | 'decibelios'
}

const EcoLabelBox = ({ children, colour, ...props }: Props) => {
  const { icon } = props
  return (
    <div className={styles.ecoLabelBox_wrapper}>
      {icon === 'consumo' && <Consumo className={styles.icon} />}
      {icon === 'lluvia' && <Lluvia className={styles.icon} />}
      {icon === 'decibelios' && <Ruido className={styles.icon} />}
      <div
        className={
          ['consumo', 'lluvia'].includes(icon)
            ? `${styles.signpost} ${styles[`signpost_${colour}`]}`
            : styles.noSignpost
        }>
        {children}
      </div>
    </div>
  )
}

export default EcoLabelBox
