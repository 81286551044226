import React, { ReactNode } from 'react'
import { IAppState } from '../../../context/context'
import { intl, locale, t } from '../../../i18n'
import * as CestaActions from '../../../context/actions/cestaActions'
import { ProductInfo } from '../../../context/actions/cestaActions'
import { DataSteps, Steps } from '../../cesta/Steps'
import { navigate } from 'gatsby'
import styles from './card.module.scss'
import ImageGallery from '../../imageGallery/ImageGallery'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { getModeloImagenes, getModeloMarca, getNeumaticoSpecs } from '../utils'
import { selectPostCode } from '../../../context/selectors/userSelectors'
import EcoLabels from '../../EcoLabels/EcoLabels'
import {
  ConsumoLevels,
  LLuviaLevels,
  RuidoLevels,
} from '../../../types/Ecolabel'
import { getImages, ImageNode } from '../../../utils/imagesS3'
import useUpdatePromocion from '../../../helpers/customHooks/useUpdatePromocion'
import { CharacteristicsParagraphs } from '../Characteristics/CharacteristicsParagraphs'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import ResponsiveSliderBanner from '../../ResponsiveSliderBanner/ResponsiveSliderBanner'
import { CharacteristicsCocheTable } from '../Characteristics/CharacteristicsCocheTable'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
  NeumaticoInfo,
} from '../types'
import { isPromocionForProductos } from '../../../context/selectors/cestaSelectors'
import { Product } from '../../../context/reducers/cestaReducer'

interface PriceProps {
  neumatico: NeumaticoInfo | Product
}
const Prices = ({ neumatico }: PriceProps) => {
  if (!neumatico) {
    return null
  }

  let crossedPrice
  if (
    (neumatico.promo && neumatico.promo !== '0.000') ||
    (neumatico.promocion &&
      neumatico.promocion.importePromocion &&
      isPromocionForProductos(neumatico.promocion))
  ) {
    crossedPrice = (
      <div className={styles.priceSinPromo}>
        <del>
          {intl.formatNumber(neumatico.importeSinPromo, {
            style: 'currency',
            currency: 'EUR',
          })}
        </del>
      </div>
    )
  }
  return (
    <div className={styles.prices}>
      {crossedPrice}
      <div className={styles.price}>
        {neumatico.promocion && isPromocionForProductos(neumatico.promocion)
          ? intl.formatNumber(
              neumatico.importe - neumatico.promocion.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.importe, {
              style: 'currency',
              currency: 'EUR',
            })}
      </div>
      {/* <Stars starsFilled={neumatico.puntuacion}/> */}
      <div className={styles.ecotasa}>
        {neumatico.promocion && isPromocionForProductos(neumatico.promocion)
          ? intl.formatNumber(
              neumatico.importeConEcotasa -
                neumatico.promocion.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.importeConEcotasa, {
              style: 'currency',
              currency: 'EUR',
            })}{' '}
        {t('resultados_neumaticos.con_ecotasa')}
      </div>
    </div>
  )
}

interface NeumaticoCardProps {
  postCode: string | null
  modelo: ModeloCocheInfo
  neumatico?: NeumaticoCocheInfo & NeumaticoCocheProducto
  banners: { [key: number]: ImageNode[] }
  cta: ReactNode
  estaciones?: string[]
  producto?: Product
}

const NeumaticoCard: React.FC<NeumaticoCardProps> = ({
  postCode,
  modelo,
  neumatico,
  banners,
  cta: Cta,
  estaciones,
  producto,
}) => {
  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modelo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modelo.id_site_modelo_neumatico,
    llanta: neumatico ? neumatico.llanta : undefined,
    runflat: neumatico ? neumatico.runflat : undefined,
    allseason: neumatico ? neumatico.allseason : undefined,
    invierno: neumatico ? neumatico.invierno : undefined,
    postCode,
  })

  const bannerImagesDesktop = getImages(banners[`${locale}_desktop`], locale)
  const bannerImagesMobile = getImages(banners[`${locale}_mobile`], locale)

  const { fichas } = getModeloImagenes(modelo.imagenes)

  return (
    <div className={styles.neumatico_card__container}>
      <div className={styles.neumatico_card__left_sidebar}>
        <ImageGallery marca={modelo.marca.logo} fotos={fichas} tipo={'ficha'} />
      </div>
      <div className={styles.neumatico_card__content}>
        <div className={styles.neumatico_card__row}>
          <div
            className={`${styles.neumatico_card__full_column} ${styles.order_1}`}>
            <h1>
              <span>{getModeloMarca(modelo).toUpperCase()}</span>{' '}
              {neumatico ? <span>{getNeumaticoSpecs(neumatico)}</span> : null}
            </h1>
            {neumatico ? (
              <EcoLabels
                consumo={neumatico.consumo as ConsumoLevels}
                lluvia={neumatico.lluvia as LLuviaLevels}
                ruido={neumatico.nivel_ruido as RuidoLevels}
                decibelios={neumatico.decibelios}
                eprel={neumatico.eprel}
              />
            ) : null}
          </div>
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_5}`}>
            <CharacteristicsParagraphs
              modelo={modelo}
              title={'landing_neumaticos.info.recomendado_para'}
              textKeys={['recomendado_para']}
            />
            <CharacteristicsParagraphs
              modelo={modelo}
              title={'landing_neumaticos.info.caracteristicas'}
              textKeys={[
                'caracteristicas1',
                'caracteristicas2',
                'caracteristicas3',
              ]}
            />
          </div>

          <div
            className={`${styles.neumatico_card__right_column} ${styles.order_2}`}>
            {producto && producto.id_navision ? (
              <Prices neumatico={neumatico} />
            ) : (
              <Prices neumatico={neumatico} />
            )}
            {Cta}
            {updatedPromotion.texto_promociones && (
              <div className={styles.neumatico_card__promobanner}>
                <PromotionBanner promocion={updatedPromotion} />
              </div>
            )}
          </div>
          {bannerImagesDesktop.length || bannerImagesMobile.length ? (
            <div
              className={`${styles.neumatico_card__full_column} ${styles.order_3}`}>
              <ResponsiveSliderBanner
                mobileImages={bannerImagesMobile}
                desktopImages={bannerImagesDesktop}
                sizesVariant={'banner_neumaticos'}
              />
            </div>
          ) : null}
          <div
            className={`${styles.neumatico_card__left_column} ${styles.order_4}`}>
            <h2>{t('landing_neumaticos.info.caracteristicas_tecnicas')}</h2>
            <CharacteristicsCocheTable
              neumatico={neumatico}
              modelo={modelo}
              estaciones={estaciones}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSelectedNeumatico: (productInfo: ProductInfo) => {
      dispatch(CestaActions.addProduct(productInfo, 'database'))
      dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
      navigate(DataSteps[Steps.TU_SOLICITUD].route)
    },
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NeumaticoCard)
